* {
  font-family: 'Arial Narrow', Arial, sans-serif;
}
html {
  box-sizing: border-box;
  min-height: 100% !important;
}
body {
  min-height: 100% !important;
}
